define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global require */

  let configModulePath = `${(0, _runtime.config)("/builds/3ker-grizzzel/candidate-portal/node_modules/.pnpm/ember-get-config@2.1.1/node_modules/ember-get-config").modulePrefix}/config/environment`;
  var _default = _exports.default = require(configModulePath).default;
});